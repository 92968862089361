<template>
  <div class="clue-reassignment-detail-page">
    <!-- <div class="cell">
      线索信息
    </div>
    <van-cell-group inset class="clue-info">
      <div class="content">
        <van-cell
          :border="false"
          @click="$router.push('/clue-incubation/details?id=' + info.leadId)"
        >
          <template #title>
            <div class="user-info">
              <span class="van-ellipsis">{{ info.userName }}</span>
              <span
                style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"
              >
              </span>
              <span>
                {{ info.userMobile }}
              </span>
            </div>
          </template>
        </van-cell>
        <van-cell :border="false" title="创建时间：" :value="info.createTime" />
      </div>
      <van-icon name="arrow" />
    </van-cell-group> -->
    <div class="cell">
      申请转入信息
    </div>
    <van-cell-group inset>
      <van-cell title="申请转入门店" :value="info.targetDealerName" />
      <van-cell title="客户姓名" :value="info.userName" />
      <van-cell title="联系电话" :value="info.userMobile" />
      <van-cell title="客户所在门店" :value="info.orgDealerName" />
    </van-cell-group>
    <div class="cell">
      申请说明
    </div>
    <van-cell-group inset>
      <van-field
        readonly
        :value="info.remark"
        rows="3"
        autosize
        type="textarea"
      />
    </van-cell-group>
    <template v-if="info.attachUrl">
      <div class="cell">
        附件
      </div>
      <van-cell-group inset style="padding: 10px 8px 2px 16px">
        <van-uploader
          :fileList="
            info.attachUrl.split(',').map((url) => ({ url, isImage: true }))
          "
          :deletable="false"
          :show-upload="false"
        />
      </van-cell-group>
    </template>
    <div v-if="checked" class="actions">
      <van-button round @click="onReview('0')">
        {{ $t("拒绝") }}
      </van-button>
      <van-button type="info" round color="#EED484" @click="onResolve">
        {{ $t("同意") }}
      </van-button>
    </div>
    <ReviewFrom
      v-model="visible"
      :checkedItems="[info.id]"
      :result="result"
      :status="info.status"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import ReviewFrom from './reviewFrom.vue'
import { detail } from '@/services/approveManage'
import { batchTransferReview } from '@/services/approveManage'

export default {
  components: {
    ReviewFrom,
  },
  data() {
    return {
      result: '',
      visible: false,
      info: {},
    }
  },
  computed: {
    checked() {
      return (
        (this.$store.getters.userInfo.type == 1 &&
          this.info.status == '1028010') ||
        (this.$store.getters.userInfo.type == 0 &&
          ['1028005', '1028015'].includes(this.info.status))
      )
    },
  },
  created() {
    detail({ id: this.$route.query.id }).then((res) => {
      this.info = res
    })
  },
  methods: {
    onConfirm() {
      this.visible = false
      this.$router.back()
    },
    onResolve(){
      this.$dialog.confirm({
        title: '',
        message: this.$t('确认同意'),
        confirmButtonColor: '#B9921A',
        confirmButtonText: this.$t('确认'),
        cancelButtonText: this.$t('取消'),
      }).then(async () => {
        // on confirm
        const params = {
          batchReqs: [{
            id: this.info.id,
            result: '1',
            status: this.info.status
          }]
        }
        const res = await batchTransferReview(params)
        this.$router.go(-1)
      }).catch(async () => {
        // on cancel
      })
    },
    onReview(result) {
      this.result = result
      this.visible = true
    },
  },
}
</script>
<style lang="less" scoped>
.clue-reassignment-detail-page {
  .clue-info {
    display: flex;
    align-items: center;
    .content {
      flex: 1;
    }
    i {
      margin-right: 16px;
    }
  }
  .cell {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 16px;
    &::before {
      content: "";
      background: #1989fa;
      width: 2px;
      height: 10px;
      margin-right: 4px;
      border-radius: 1px;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .actions {
    padding: 16px;
    display: flex;
    justify-content: space-evenly;
    button {
      width: 40%;
    }
  }
}
</style>
